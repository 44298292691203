@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Toastify */
.Toastify__toast {
  border-radius: 11px !important;
  border: 1px solid #e5e7eb;
  font-size: 15px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
}

.Toastify__toast-body {
  color: black;
  font-family: system-ui;
  font-weight: 450;
  padding: 5px 15px !important;
}

.Toastify__toast-icon {
  height: 25px !important;
  width: 25px !important;
}

.Toastify__close-button {
  opacity: 0.4;
  padding: 6px !important;
}

/* End Toastify */